import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {ZiggyVue} from 'ziggy-js';
import 'quasar/dist/quasar.css'
import {Loading, Notify, Quasar} from "quasar";
import 'flowbite';
// Import Quasar css
import 'quasar/src/css/index.sass'
import * as Sentry from "@sentry/vue";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import {gsap} from "gsap";

import {ExpoScaleEase, SlowMo} from "gsap/EasePack";

import {Flip} from "gsap/Flip";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {Observer} from "gsap/Observer";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {Draggable} from "gsap/Draggable";
import {MotionPathPlugin} from "gsap/MotionPathPlugin";
import {EaselPlugin} from "gsap/EaselPlugin";
import {PixiPlugin} from "gsap/PixiPlugin";
import {TextPlugin} from "gsap/TextPlugin";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';
// Import icon libraries
import('@quasar/extras/material-icons/material-icons.css');
// Import all from folder
// Import icon libraries
import('@quasar/extras/roboto-font-latin-ext/roboto-font-latin-ext.css');
import('@quasar/extras/material-symbols-outlined/material-symbols-outlined.css');
import('@quasar/extras/material-symbols-rounded/material-symbols-rounded.css');
import('animate.css');


gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, EaselPlugin, PixiPlugin, TextPlugin, ExpoScaleEase, SlowMo);

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'));
    },
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)});
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                    networkDetailAllowUrls: ['https://unsparkai.com/*'],
                }),
                // Sentry.feedbackIntegration({
                    // Additional SDK configuration goes in here, for example:
                //
                //     colorScheme: "system",
                //     isEmailRequired: true,
                //     showBranding: false,
                //     messagePlaceholder: "What's the problem? What did you expect from the application?",
                //     successMessageText: "Thank you for your feedback, if you provided an email we will get back to you shortly.",
                // }),
                Sentry.browserTracingIntegration({
                    enableInp: true,
                }),
            ],
            profilesSampleRate: 1.0,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.08,
            replaysOnErrorSampleRate: 1.0,
        });

        return app.use(plugin)
            .use(ZiggyVue)
            .use(Quasar, {
                plugins: {
                    Notify,
                    Loading
                }, // import Quasar plugins and add here
                /*
				config: {
				  brand: {
					// primary: '#e46262',
					// ... or all other brand colors
				  },
				  notify: {...}, // default set of options for Notify Quasar plugin
				  loading: {...}, // default set of options for Loading Quasar plugin
				  loadingBar: { ... }, // settings for LoadingBar Quasar plugin
				  // .and many more (check Installation card on each Quasar component/directive/plugin)
				}
				*/
            })
            .mount(el);
    },
    progress: {
        delay: 10,
        color: '#c41717',
        showSpinner: false,
    },
});
